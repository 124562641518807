var body = document.querySelector("body");
window.onload = function () {
  body.classList.add("ready");
};

var Masonry = require("masonry-layout");

var masonry = document.querySelector(".masonry");
if (masonry) {
  document.querySelectorAll(".masonry").forEach((i) => {
    var msnry = new Masonry(i, {
      columnWidth: 600,
      itemSelector: ".gallery-image",
      gutter: 10,
    });
  });
}

var Isotope = require("isotope-layout");
// require("isotope-packery");
var singleIso = document.querySelector(".project-listing");
if (singleIso) {
  var iso = new Isotope(".project-listing", {
    itemSelector: ".project-il",
    transitionDuration: 500,
    layoutMode: "fitRows",
    // layoutMode: "packery",
  });
}

function projectFilter() {
  var filtersElem = document.querySelector(".filters");
  if (filtersElem) {
    filtersElem.addEventListener("click", function (event) {
      var filterValue = event.target.getAttribute("data-filter");
      iso.arrange({ filter: filterValue });
    });

    var filters = document.querySelectorAll("a.filter");

    if (filters) {
      document.querySelectorAll("a.filter").forEach((i) => {
        i.addEventListener("click", (e) => {
          e.preventDefault();
          const isSelected = document.querySelector(".is-selected");
          if (isSelected) {
            isSelected.classList.remove("is-selected");
          }
          e.currentTarget.classList.add("is-selected");
        });
      });
    }
  }
}

window.addEventListener("load", projectFilter, false);

// Debounce function
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

// Function to add 'scrolled' class to the body
function checkScroll() {
  if (window.scrollY > 2000) {
    document.body.classList.add("scrolled");
  } else {
    document.body.classList.remove("scrolled");
  }
}

// Debounced version of the checkScroll function
var debouncedCheckScroll = debounce(checkScroll, 50);

// Event listener for scroll
window.addEventListener("scroll", debouncedCheckScroll);
