require("intersection-observer");

// import "./utils/easing.js";
import "./utils/device.js";
import "./utils/jq.js";
import "./main.js";
import "./components/navigation.js";
import LazyLoad from "vanilla-lazyload";
const lazyLoadOptions = {};
const pageLazyLoad = new LazyLoad(lazyLoadOptions);
