var classNames = [];
if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i))
  classNames.push("device-ios");
if (navigator.userAgent.match(/android/i)) classNames.push("device-android");
if (navigator.userAgent.match(/Trident/i)) classNames.push("internet-explorer");
if (window.innerHeight > window.innerWidth) classNames.push("device-rotated");
var html = document.getElementsByTagName("html")[0];

if (classNames.length) classNames.push("on-dev");
if (!classNames.length) classNames.push("no-dev");
if (html.classList) html.classList.add.apply(html.classList, classNames);
