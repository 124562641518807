jQuery(document).ready(function ($) {
  var $pHead = $("#page-head");
  var $limit = $pHead.height();
  $(window).on("scroll", function () {
    var $st = $(this).scrollTop();
    if ($st <= $limit) {
      $pHead.css({ opacity: 1 - $st / $limit });
    }
  });

  // $(document).on("click", 'a[href^="#"]', function (event) {
  //   event.preventDefault();
  //   $("html, body")
  //     .stop()
  //     .animate(
  //       {
  //         scrollTop: $($.attr(this, "href")).offset().top - 48,
  //       },
  //       850,
  //       // include easing.js for more control over ease
  //       "easeInOutQuint"
  //     );
  // });

  $(".split").html(function (index, html) {
    return html.replace(/\S/g, "<span class='animate-in'>$&</span>");
  });

  $("#mobile-menu .menu-item-has-children a").click(function (e) {
    $(this).parent().addClass("sub-opened");
    $(this).parents("#mobile-menu").addClass("sub-open");
  });
  $(".close-sub .mobile-menu-item").click(function (e) {
    $(this).parents(".sub-opened").removeClass("sub-opened");
    $(this).parents("#mobile-menu").removeClass("sub-open");
  });
  $("[aria-current='page']").click(function (e) {
    $("#mobile-nav").removeClass("mobile-menu-open");
    $("#menu-toggle").removeClass("toggled");
    $(this).parents(".sub-opened").removeClass("sub-opened");
    $(this).parents("#mobile-menu").removeClass("sub-open");
  });
});
